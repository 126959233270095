import firebase from 'firebase/compat/app'
import shortUuid from 'short-uuid'
import axios from 'axios'
import clientconfig from 'clientconfig'
import valueIsRecord from '@apphiveio/controlsmanager/typeAssertions/valueIsRecord'
import { FUNCTION_IDS } from '@apphiveio/controlsmanager/types/Functions'
import callableBuildCodeCompute from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableBuildCodeCompute'
import callableObjectSet from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableObjectSet'
import callableObjectConcat from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableObjectConcat'
import callableUnknownToJsonString from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableUnknownToJsonString'
import callableArrayFilter from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableArrayFilter'
import buildCallableDebugLog from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableDebugLog'
import callableObjectBuild from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableObjectBuild'
import callableStringInterpolation from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableStringInterpolation'
import buildCallableHttpRequest from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableHttpRequest'
import buildCallableOpenUrl from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableOpenUrl'
import callableGenerateSwiperContent from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGenerateSwiperContent'
import buildCallableOpenGeoMap from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableOpenGeoMap'
import buildCallableMakeCall from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableMakeCall'
import buildCallablePrintRemotePDF from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallablePrintRemotePDF'
import localStorageSetData from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/localStorageSetData'
import localStorageSetDataDirect from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/localStorageSetDataDirect'
import localStorageGetData from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/localStorageGetData'
import localStorageDelete from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/localStorageDelete'
import { FunctionsDefinitions } from '@apphiveio/controlsmanager/types/Runtime'
import modifyControl from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/modifyControl'
import localStorageDeleteAllData from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/localStorageDeleteAllData'
import buildReplaceTopPage from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildReplaceTopPage'
import buildReplacePage from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildReplacePage'
import buildPushPage from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildPushPage'
import setDataDBBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/setDataDBBuilder'
import rangeIteratorBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/rangeIteratorBuilder'
import getDataDBBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/getDataDBBuilder'
import deleteDataDBBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/deleteDataDBBuilder'
import arithmeticOperation from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/arithmeticOperation'
import arrayFromObjectBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/arrayFromObjectBuilder'
import chronometerBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/chronometerBuilder'
import concatBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/concatBuilder'
import conditionalBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/conditionalBuilder'
import debounceBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/debounceBuilder'
import forEachBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/forEachBuilder'
import isUndefinedBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/isUndefinedBuilder'
import jsonStringifyBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/jsonStringifyBuilder'
import objectKeysBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/objectKeysBuilder'
import randomNumberBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/randomNumberBuilder'
import regexTestBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/regexTestBuilder'
import setTimeOutBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/setTimeOutBuilder'
import buildCallableSendPush from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableSendPush'
import uuidV1Builder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/uuidV1Builder'
import setDataDbDirectBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/setDataDbDirectBuilder'
import switchBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/switchBuilder'
import callableSendAlert from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableSendAlert'
import buildLoginWithFacebook from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildLoginWithFacebook'
import searchInObjectBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/searchInObjectBuilder'
import buildCallableGeocoding from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableGeocoding'
import setPageValueBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/setPageValueBuilder'
import buildCallableGetDistance from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableGetDistance'
import callableIsCoordinateInsidePoligon from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableIsCoordinateInsidePoligon'
import buildBackPage from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildBackPage'
import isLoggedIn from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/isLoggedIn'
import buildAppLogin from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildAppLogin'
import callableGeoFireGetAllBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGeoFireGetAllBuilder'
import callableChangeMyPassword from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableChangeMyPassword'
import callableToggleBottomMenu from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableToggleBottomMenu'
import callableUpdateDataFromOtherUser from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableUpdateDataFromOtherUser'
import callableSetUserCustomData from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableSetUserCustomData'
import callableGetAllUsers from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGetAllUsers'
import callableSetOtherUserCustomData from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableSetOtherUserCustomData'
import callableGetAppUsers from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGetAppUsers'
import getDataFromOtherUserBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/getDataFromOtherUserBuilder'
import buildCallableLogout from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableLogout'
import buildCallableIsAudioPlaying from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableIsAudioPlaying'
import callableSetPaletteColors from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableSetPaletteColors'
import buildCallableSignIn from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableSignIn'
import callableTogglePageLoader from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableTogglePageLoader'
import updateAuthInfoBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/updateAuthInfoBuilder'
import callableIterateChildren from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableIterateChildren'
import buildCallableGetDeviceInfo from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableGetDeviceInfo'
import buildCallableLoginWithGoogle from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableLoginWithGoogle'
import buildCallableGetLocation from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableGetLocation'
import callablePushCollectionDOM from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callablePushCollectionDOM'
import callableOpenSideMenu from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableOpenSideMenu'
import buildCallableOpenWhatsApp from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableOpenWhatsApp'
import callableForgetPassword from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableForgetPassword'
import callableSetAppValue from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableSetAppValue'
import getScreenDimentionsBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/getScreenDimentionsBuilder'
import buildCallableShowFileBrowser from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableShowFileBrowser'
import valueIsNull from '@apphiveio/controlsmanager/typeAssertions/valueIsNull'
import buildCallableUploadFile from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableUploadFile'
import callableTriggerEvent from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableTriggerEvent'
import callableGlobalFormatterBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGlobalFormatterBuilder'
import callableBasedOnCurrentOs from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableBasedOnCurrentOs'
import callableConfirmationAlert from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableConfirmationAlert'
import callableStopSetInterval from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableStopSetInterval'
import callableSetInterval from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableSetInterval'
import callableInputDialog from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableInputDialog'
import buildCallableCopyClipboard from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableCopyClipboard'
import buildCallablePausePlayingAudio from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallablePausePlayingAudio'
import callableGeoFireSetBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGeoFireSetBuilder'
import callableGeoFireListenItemBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGeoFireListenItemBuilder'
import callableGeoFireRemoveBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGeoFireRemoveBuilder'
import callableGeoFireGetBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGeoFireGetBuilder'
import callableGeoFireQueryAllBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGeoFireQueryAllBuilder'
import callableGeoFireQueryBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGeoFireQueryBuilder'
import callableTakePhoto from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableTakePhoto'
import callableTakeVideo from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableTakeVideo'
import callableReadQRCode from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableReadQRCode'
import apiModuleBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/apiModuleBuilder'
import buildCallableResizeImage from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableResizeImage'
import buildCallableSetAudioTime from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableSetAudioTime'
import buildCallableStartPlayingAudio, { SoundPlayingState } from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableStartPlayingAudio'
import buildCallableStartRecordingAudio from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableStartRecordingAudio'
import buildCallableStopRecordingAudio from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableStopRecordingAudio'
import callableReadBarCode from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableReadBarCode'
import buildCallableStopPlayingAudio from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableStopPlayingAudio'
import buildCallableCollectionFilter from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableCollectionFilter'
import buildCallableDownloadFile, { getFileName } from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableDownloadFile'
import callableGetUserOtherApp from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableGetUserOtherApp'
import buildCallableIsCurrentUserEmailVerified from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableIsCurrentUserEmailVerified'
import buildCallableSendEmailVerification from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableSendEmailVerification'
import buildCallablePushNotificationRequestPermission from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallablePushNotificationRequestPermission'
import buildCallableFirebaseRealtimeDatabaseSet from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/buildCallableFirebaseRealtimeDatabaseSet'
import callableObjectDelete from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableObjectDelete'
import callableObjectFilter from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/callableObjectFilter'
import { odCreatorApp } from 'firebaseConnection'
import openCalendar from 'components/atoms/ControlOpenCalendar'
import authLoginWithProvider from 'shared/authLoginWithProvider'
import reportCloudFunctionCall from 'shared/reportCloudFunctionCall'
import audioRecorderCoreBuilder from 'shared/audioRecorderCoreBuilder'
import resizeImage from 'shared/resizerImageFromUrl'
import appProcessBuilder from '@apphiveio/controlsmanager/applicationFunctionality/functionBuilders/appProcessBuilder'
import { getApiBaseInputs, setApiBaseInputs } from '@apphiveio/controlsmanager/shared/apisLocalInformation'
import getRenderConfig from 'shared/getRenderConfig'
import { generateCloudMessage } from '@apphiveio/controlsmanager/firebase/serverFunctions'
import { saveClientAppTokenInPreviewer } from 'shared/rendererAuthentication'
import buildCallableNotSupported from './buildCallableNotSupported'
import { WEBAPP_DIMENTIONS } from './index'

let playingAudio: HTMLAudioElement | undefined
const getStateAudioPlaying = async () => {
    if (playingAudio && playingAudio.paused) {
        return SoundPlayingState.PAUSED
    }
    if (playingAudio
        && !playingAudio.paused
        && !playingAudio.ended) {
        return SoundPlayingState.PLAYING
    }
    return SoundPlayingState.NONE
}
const webappFunctions: FunctionsDefinitions = {}
webappFunctions.getDeviceInfo = buildCallableGetDeviceInfo({
    getBuildId: { status: () => false, function: () => 'THIS FUNCTION IS NOT AVAILABLE FOR WEBAPP.' },
    getBatteryLevel: {
        status: () => {
            let result = true
            const navigator = window.navigator as unknown
            if (!valueIsRecord(navigator)) {
                result = false
                return result
            }
            const getBattery = navigator.getBattery as unknown
            if (typeof getBattery !== 'function') {
                result = false
                return result
            }
            return result
        },
        function: () => {
            const noSupported = 'THIS FUNCTION IS NOT AVAILABLE IN YOUR BROWSER'
            const navigator = window.navigator as unknown
            if (!valueIsRecord(navigator)) {
                return noSupported
            }
            const getBattery = navigator.getBattery as unknown
            if (typeof getBattery !== 'function') {
                return noSupported
            }
            const batteryResolve = getBattery.call(navigator)
                .then((batteryLevel: unknown) => {
                    if (!valueIsRecord(batteryLevel)) {
                        return noSupported
                    }
                    return batteryLevel.level
                })
            return batteryResolve
        },
    },
    getBrand: { status: () => false, function: () => 'THIS FUNCTION IS NOT AVAILABLE FOR WEBAPP.' },
    getBuildNumber: { status: () => true, function: () => getRenderConfig().versionNumber },
    getCarrier: { status: () => false, function: () => 'THIS FUNCTION IS NOT AVAILABLE FOR WEBAPP.' },
    getDeviceId: { status: () => false, function: () => 'THIS FUNCTION IS NOT AVAILABLE FOR WEBAPP.' },
    getUniqueId: { status: () => false, function: () => 'THIS FUNCTION IS NOT AVAILABLE FOR WEBAPP.' },
})
webappFunctions.loginWithApple = () => null
webappFunctions.dismissKeyboard = () => null
webappFunctions.iterateChildren = callableIterateChildren

const recorder = audioRecorderCoreBuilder()

webappFunctions.startRecordingAudio = buildCallableStartRecordingAudio(recorder.onStartRecord)
webappFunctions.stopRecordingAudio = buildCallableStopRecordingAudio(recorder.onStopRecord)

webappFunctions.updateAuthInfo = updateAuthInfoBuilder
webappFunctions.globalFormatter = callableGlobalFormatterBuilder()
webappFunctions.setPageValue = setPageValueBuilder
webappFunctions.concat = concatBuilder()
webappFunctions.deleteAllDataLocal = localStorageDeleteAllData

webappFunctions.pushNotificationRequestPermission = buildCallablePushNotificationRequestPermission({
    requestPermission: async () => undefined,
    getToken: () => odCreatorApp.messaging().getToken({
        vapidKey: getRenderConfig()?.pushNotificationVapidPublicKey,
    }),
})

webappFunctions.jsonStringify = jsonStringifyBuilder()
webappFunctions.objectKeys = objectKeysBuilder()
webappFunctions.arrayFromObject = arrayFromObjectBuilder
webappFunctions.apiModule = apiModuleBuilder({
    axios,
    getApiBaseInputs: getApiBaseInputs(async (key) => window.localStorage.getItem(key)),
    setApiBaseInputs: setApiBaseInputs(async (key, val) => window.localStorage.setItem(key, val)),
})
webappFunctions.pushCollectionDOM = callablePushCollectionDOM
webappFunctions.makeCall = buildCallableMakeCall({
    openUrl: async (phoneNumber) => {
        window.location.href = `tel://${phoneNumber}`
    },
})
webappFunctions.vibration = () => null
webappFunctions.getLocation = buildCallableGetLocation({
    onGetLocation: async ({
        desiredAccuracy,
    }) => new Promise((resolve, reject) => {
        navigator
            .geolocation
            .getCurrentPosition(
                ({ coords }) => resolve({
                    latitude: coords.latitude,
                    longitude: coords.longitude,
                }),
                async ({ message }) => {
                    const checkPermissions = await navigator.permissions.query({ name: 'geolocation' })
                    if (checkPermissions.state !== 'granted') {
                        return reject(new Error('Without permissions, enter settings and accept permissions'))
                    }
                    return reject(new Error(message))
                },
                {
                    enableHighAccuracy: desiredAccuracy === 'highAccuracy',
                    timeout: 6000,
                    maximumAge: 0,
                },
            )
    }),
})
webappFunctions.openURL = buildCallableOpenUrl({
    openOnPlatform: async (url) => {
        window.open(url, '_blank')
    },
})
webappFunctions.randomNumber = randomNumberBuilder()
webappFunctions.getDataDB = getDataDBBuilder({ isDevelopment: getRenderConfig().isRenderer })
webappFunctions.setDataDB = setDataDBBuilder({ isDevelopment: getRenderConfig().isRenderer })
webappFunctions.deleteDataDB = deleteDataDBBuilder({
    isDevelopment: getRenderConfig().isRenderer,
})
webappFunctions.sendAlert = callableSendAlert
webappFunctions.isUndefined = isUndefinedBuilder()
webappFunctions.conditional = conditionalBuilder()
webappFunctions.arithmetic = arithmeticOperation
webappFunctions.showFileBrowser = buildCallableShowFileBrowser({
    showFileBrowser: async ({
        typeFile,
        isMediaGalery,
        multipleFiles,
    }) => {
        let inputType = ''

        switch (typeFile) {
        case 'audio':
            inputType = 'audio/*'
            break
        case 'images':
            inputType = 'image/*'
            break
        case 'pdf':
            inputType = '.pdf'
            break
        case 'plainText':
            inputType = 'text/plain'
            break

        default:
            inputType = '*/*'
            break
        }
        if (isMediaGalery) {
            inputType = 'image/*,video/*'
        }

        const openAsyncFileBrowser = new Promise<string[]>((resolve) => {
            const input = document.createElement('input')
            input.type = 'file'
            input.accept = inputType
            input.multiple = multipleFiles
            input.onchange = async (e: Event) => {
                const target = e.target as HTMLInputElement

                if (!valueIsNull(target.files)) {
                    const uris = Array.from(target.files)
                        .map((file) => URL.createObjectURL(file))
                    resolve(uris)
                }
            }
            input.click()
        })

        const uris = await openAsyncFileBrowser
        return uris
    },
})
webappFunctions.isLoggedIn = isLoggedIn
webappFunctions.forgetPassword = callableForgetPassword
webappFunctions.openSideMenu = callableOpenSideMenu
webappFunctions[FUNCTION_IDS.CODE_COMPUTE] = callableBuildCodeCompute()
webappFunctions[FUNCTION_IDS.OBJECT_SET] = callableObjectSet
webappFunctions[FUNCTION_IDS.OBJECT_CONCAT] = callableObjectConcat
webappFunctions[FUNCTION_IDS.UNKNOWN_TO_JSON_STRING] = callableUnknownToJsonString
webappFunctions[FUNCTION_IDS.ARRAY_FILTER] = callableArrayFilter
webappFunctions.a = callableObjectBuild
webappFunctions.b = callableStringInterpolation
webappFunctions.c = buildCallableHttpRequest(axios)
webappFunctions.d = buildCallableDebugLog({
    axios,
    hostname: clientconfig.getServicesUrls().graphql,
})
webappFunctions.f = buildCallableFirebaseRealtimeDatabaseSet({
    setFirebaseDatabase: async ({
        path, value,
    }) => odCreatorApp.database().ref(path).set(value),
})
webappFunctions[FUNCTION_IDS.OBJECT_DELETE] = callableObjectDelete
webappFunctions[FUNCTION_IDS.OBJECT_FILTER] = callableObjectFilter
webappFunctions.takePhoto = callableTakePhoto
webappFunctions.takeVideo = callableTakeVideo
webappFunctions.chronometer = chronometerBuilder()
webappFunctions.openCalendar = openCalendar
webappFunctions.login = buildAppLogin()
webappFunctions.loginWithGoogle = buildCallableLoginWithGoogle({
    identifyState: ({ types }) => types.unknown,
    onUserLoggedInDevelopment: saveClientAppTokenInPreviewer,
    localLoginWithGoogle: async () => {
        await authLoginWithProvider({
            firebaseApp: odCreatorApp,
            provider: new firebase.auth.GoogleAuthProvider(),
            mode: 'popup',
        })
    },
})
webappFunctions.loginWithFacebook = buildLoginWithFacebook({
    identifyError: ({ types }) => types.unknown,
    onUserLoggedInDevelopment: saveClientAppTokenInPreviewer,
    localLoginWithFacebook: async () => {
        await authLoginWithProvider({
            firebaseApp: odCreatorApp,
            provider: new firebase.auth.FacebookAuthProvider(),
            mode: 'popup',
        })
    },
})
webappFunctions.pushWindow = buildPushPage
webappFunctions.replaceWindow = buildReplacePage
webappFunctions.replaceTopPage = buildReplaceTopPage
webappFunctions.uuidV1 = uuidV1Builder()
webappFunctions.logout = buildCallableLogout()
webappFunctions.signIn = buildCallableSignIn()
webappFunctions.modifyDOM = modifyControl
webappFunctions.backSreen = buildBackPage
webappFunctions.togglePageLoader = callableTogglePageLoader
webappFunctions.startGeolocationTracking = () => null
webappFunctions.startGeolocationTrackingHttp = () => null
webappFunctions.stopGeolocationTracking = () => null
webappFunctions.openWhatsApp = buildCallableOpenWhatsApp({
    openWhatsApp: async (phoneNumber, message) => {
        const url = `https://wa.me/${phoneNumber}?text=${message}`
        window.open(url, '_blank')?.focus()
    },
})
webappFunctions.readQRCode = callableReadQRCode
webappFunctions.openGeoMap = buildCallableOpenGeoMap({
    openGeoMap: async (latitude, longitude) => {
        const url = `http://maps.google.com/maps?q=${latitude},${longitude}`
        window.open(url, '_blank')?.focus()
    },
})
webappFunctions.deleteDataLocal = localStorageDelete
webappFunctions.getDataLocal = localStorageGetData
webappFunctions.setDataLocal = localStorageSetData
webappFunctions.regexTest = regexTestBuilder(true)
webappFunctions.regexTest2 = regexTestBuilder()
webappFunctions.uploadFile = buildCallableUploadFile({
    checkPermissions: () => true,
    getFileInfo: async ({
        refStorage,
        uris,
        validateSize,
    }) => {
        const urisInfo = await Promise.all(uris.map(async (tempUri) => {
            const response = await fetch(tempUri)
            const blob = await response.blob()
            const sizeMB = blob.size / 1e+6
            validateSize(sizeMB)

            return { blob }
        }))

        return Promise.all(uris.map(async (_, index) => {
            const fileId = shortUuid.generate()
            const fileBlob = urisInfo[index].blob
            await refStorage.child(fileId).put(fileBlob)

            return fileId
        }))
    },
})
webappFunctions.appProcess = appProcessBuilder({
    getApiBaseInputs: getApiBaseInputs(async (key) => window.localStorage.getItem(key)),
    setApiBaseInputs: setApiBaseInputs(async (key, val) => window.localStorage.setItem(key, val)),
    functionDefinitions: webappFunctions,
    reportCloudFunctionCall,
})
webappFunctions.sendPush = buildCallableSendPush(
    getRenderConfig().isRenderer,
    (params) => generateCloudMessage(odCreatorApp.functions())(params),
)
webappFunctions.changeMyPassword = callableChangeMyPassword
webappFunctions.triggerAppProcess = () => null
webappFunctions.generateSwiperContent = () => null
webappFunctions.forEach = forEachBuilder(webappFunctions)
webappFunctions.collectionFilter = buildCallableCollectionFilter(webappFunctions)
webappFunctions.geoFireGet = callableGeoFireGetBuilder
webappFunctions.geoFireQuery = callableGeoFireQueryBuilder
webappFunctions.geoFireQueryAll = callableGeoFireQueryAllBuilder
webappFunctions.geoFireRemove = callableGeoFireRemoveBuilder
webappFunctions.geoFireSet = callableGeoFireSetBuilder
webappFunctions.geoFireGetAll = callableGeoFireGetAllBuilder
webappFunctions.geoFireSetMultiple = () => null
webappFunctions.geoFireListenItem = callableGeoFireListenItemBuilder
webappFunctions.startPlayingAudio = buildCallableStartPlayingAudio({
    startPlayingAudio: async () => {
        if (playingAudio) {
            playingAudio.play()
        }
    },
    addRecord: async (uri: string) => {
        playingAudio = new Audio(uri)
        return playingAudio
    },
    getState: getStateAudioPlaying,
    destroy: () => {
        if (playingAudio) {
            playingAudio?.remove()
        }
    },
    getCurrentAudioPosition: async () => (
        (playingAudio) ? Math.trunc(playingAudio?.currentTime) : 0
    ),
})
webappFunctions.pausePlayingAudio = buildCallablePausePlayingAudio({
    getState: getStateAudioPlaying,
    pauseAudio: async () => {
        if (playingAudio) {
            playingAudio.pause()
        }
    },
})
webappFunctions.setAudioTime = buildCallableSetAudioTime({
    setAudioTime: async (seconds) => {
        if (playingAudio) {
            playingAudio.currentTime = seconds
        }
    },
})
webappFunctions.stopPlayingAudio = buildCallableStopPlayingAudio({
    getState: getStateAudioPlaying,
    stopAudio: async () => {
        if (playingAudio) {
            playingAudio.pause()
            playingAudio.currentTime = 0
            playingAudio = undefined
        }
    },
})
webappFunctions.isAudioPlaying = buildCallableIsAudioPlaying({
    getState: getStateAudioPlaying,
})
webappFunctions.debounce = debounceBuilder()
webappFunctions.getDistance = buildCallableGetDistance({
    getGoogleDistanceMatrix: async (args) => {
        const service = new window.google.maps.DistanceMatrixService()
        const result = await service.getDistanceMatrix({
            origins: [{ lat: args.origin.latitude, lng: args.origin.longitude }],
            destinations: [{ lat: args.destination.latitude, lng: args.destination.longitude }],
            travelMode: google.maps.TravelMode.DRIVING,
            unitSystem: google.maps.UnitSystem.METRIC,
            avoidHighways: false,
            avoidTolls: false,
        })

        const { distance, duration, status } = result.rows[0].elements[0]

        if (status !== 'OK') {
            throw new Error('No route between points')
        }

        return {
            meters: distance.value,
            kilometers: distance.value / 1000,
            estimatedTime: duration.value,
        }
    },
})
webappFunctions.getAllUsers = callableGetAllUsers
webappFunctions.getAppUsers = callableGetAppUsers
webappFunctions.getDataFromOtherUser = getDataFromOtherUserBuilder
webappFunctions.updateDataFromOtherUser = callableUpdateDataFromOtherUser
webappFunctions.setOtherUserCustomData = callableSetOtherUserCustomData
webappFunctions.rangeIterator = rangeIteratorBuilder()
webappFunctions.setTimeOut = setTimeOutBuilder
webappFunctions.toggleBottomMenu = callableToggleBottomMenu
webappFunctions.getConnectionType = buildCallableNotSupported('Get connection type')
webappFunctions.basedOnCurrentOs = callableBasedOnCurrentOs
webappFunctions.readSMSAndroid = () => null
webappFunctions.setDataDbDirect = setDataDbDirectBuilder({
    isDevelopment: getRenderConfig().isRenderer,
})
webappFunctions.setDataDbLocalDirect = localStorageSetDataDirect
webappFunctions.stopSetInterval = callableStopSetInterval
webappFunctions.setInterval = callableSetInterval
webappFunctions.switch = switchBuilder()
webappFunctions.share = buildCallableNotSupported('Share')
webappFunctions.confirmationAlert = callableConfirmationAlert
webappFunctions.readBarCode = callableReadBarCode
webappFunctions.toastNotification = ({
    entryVars: {
        message: messageEntryVar,
        gravity: gravityEntryVar,
        duration: durationEntryVar,
    },
}) => {
    let duration
    switch (durationEntryVar) {
    case 'long':
        duration = 'long'
        break
    case 'short':
        duration = 'short'
        break
    default:
        duration = 'short'
        break
    }
    let gravity
    switch (gravityEntryVar) {
    case 'top':
        gravity = 'top'
        break
    case 'center':
        gravity = 'center'
        break
    case 'bottom':
        gravity = 'bottom'
        break
    default:
        gravity = 'bottom'
        break
    }
    const toastWrapper = document.getElementById('toast-container')
    const children = toastWrapper?.children.length || 0
    const toastItem = document.createElement('div')
    toastItem.className = 'toast-item'
    toastItem.classList.add(duration, gravity)
    toastItem.innerText = String(messageEntryVar)
    toastItem.onanimationend = () => {
        const nextChild = toastItem.nextElementSibling
        toastWrapper?.removeChild(toastItem)
        nextChild?.classList.add('anim')
    }
    toastWrapper?.appendChild(toastItem)

    if (children === 0) {
        toastItem.classList.add('anim')
    }
}
webappFunctions.setAppValue = callableSetAppValue
webappFunctions.getScreenDimention = getScreenDimentionsBuilder({
    getScreenDimentions: () => ({
        width: WEBAPP_DIMENTIONS.windowWidth,
        height: WEBAPP_DIMENTIONS.windowHeight,
    }),
})
webappFunctions.inputDialog = callableInputDialog
webappFunctions.searchInObject = searchInObjectBuilder()
webappFunctions.setUserCustomData = callableSetUserCustomData
webappFunctions.geocoding = buildCallableGeocoding(axios)
webappFunctions.triggerEvent = callableTriggerEvent
webappFunctions.pushNotificationsCancelAll = () => null
webappFunctions.openPhoneSettings = buildCallableNotSupported('Open phone settings')
webappFunctions.printRemotePDF = buildCallablePrintRemotePDF({
    openPdfToPrint: async (uri) => {
        const pdfWindow = window.open(uri)
        pdfWindow?.print()
    },
})
webappFunctions.payUsingAppleWallet = () => null
webappFunctions.inAppPurchase = () => null
webappFunctions.inAppPurchaseGetHistory = () => null
webappFunctions.interstitialAds = () => null
webappFunctions.resizeImage = buildCallableResizeImage({ resizeImage })
webappFunctions.uxCamRecord = () => null
webappFunctions.isCoordinateInsidePoligon = callableIsCoordinateInsidePoligon
webappFunctions.setPaletteColors = callableSetPaletteColors
webappFunctions.showBubble = buildCallableNotSupported('Show bubble')
webappFunctions.permissionRequestBubble = buildCallableNotSupported('Permission request bubble')
webappFunctions.setBadgeBubble = buildCallableNotSupported('Set badge bubble')
webappFunctions.hiddeBubble = buildCallableNotSupported('Hidde bubble')
webappFunctions.copyClipboard = buildCallableCopyClipboard(({
    onCopy: (value) => {
        const textField = document.createElement('textarea')
        textField.innerText = value
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    },
}))
webappFunctions.generateSwiperContent = callableGenerateSwiperContent
webappFunctions.launchAppReview = buildCallableNotSupported('Launch app review')
webappFunctions.downloadFile = buildCallableDownloadFile({
    onDownloadFile: async (url) => {
        const response = await fetch(url, { method: 'GET' })
        const contentTypeResponse = response.headers.get('Content-Type')
        const fileName = getFileName(url, contentTypeResponse)
        const urlBlob = window.URL.createObjectURL(new Blob([await response.blob()]))
        const link = document.createElement('a')
        link.href = urlBlob
        document.body.appendChild(link)
        link.setAttribute('download', fileName)
        link.click()
        link.parentNode?.removeChild(link)
        return ''
    },
})
webappFunctions.showImageViewer = buildCallableNotSupported('Show image viewer')
webappFunctions.launchAppUpdate = buildCallableNotSupported('Launch app update')
webappFunctions.getUserOtherApp = callableGetUserOtherApp
webappFunctions.isCurrentUserEmailVerified = buildCallableIsCurrentUserEmailVerified({
    checkInLocalIfUserEmailVerified: async (userUid, clientProjectId) => {
        const response = await axios.get(`${clientconfig.getServicesUrls().graphql}/previewer/auth/checkUserEmailIsVerified`, {
            params: {
                userUid,
                clientProjectId,
            },
        })
        return response.data
    },
    checkInHostIfAuthUserEmailIsValidated: async () => {
        let user = odCreatorApp.auth().currentUser
        if (user) {
            await user.reload()
            user = odCreatorApp.auth().currentUser
        }
        if (!user) throw new Error('Not user found')
        const emailProvider = user.providerData?.find((providerData) => providerData?.providerId === 'password')
        if (user.emailVerified || !emailProvider) return true
        return false
    },
})
webappFunctions.sendEmailVerification = buildCallableSendEmailVerification({
    sendEmailToValidateEmail: async (userUid, userEmail, clientProjectId, clientAppId) => {
        const response = await axios.post(`${clientconfig.getServicesUrls().graphql}/previewer/auth/sendEmailToValidateEmail`, {
            userUid,
            userEmail,
            clientProjectId,
            clientAppId,
        })
        return response.data
    },
    checkInLocalIfUserEmailVerified: async (userUid, clientProjectId) => {
        const response = await axios.get(`${clientconfig.getServicesUrls().graphql}/previewer/auth/checkUserEmailIsVerified`, {
            params: {
                userUid,
                clientProjectId,
            },
        })
        return response.data
    },
    checkInHostIfAuthUserEmailIsValidated: async () => {
        let user = odCreatorApp.auth().currentUser
        if (user) {
            await user.reload()
            user = odCreatorApp.auth().currentUser
        }
        if (!user) throw new Error('Not user found')
        const emailProvider = user.providerData?.find((providerData) => providerData?.providerId === 'password')
        if (user.emailVerified || !emailProvider) return true
        return false
    },
})

export default webappFunctions
