/* eslint-disable indent */
/* eslint-disable comma-dangle */
/* eslint-disable no-trailing-spaces */

type StaticImages = {
    readonly [fileName: string]: {
        uri?: string;
    };
}

const imageStaticSourcesByFileName: StaticImages = {
    'cWwbhddcJtT3EsfMCbboUQ.png': { uri: '/images/cWwbhddcJtT3EsfMCbboUQ.png' },
'x4nYXb2JWZKeF9Mih4uCgh.png': { uri: '/images/x4nYXb2JWZKeF9Mih4uCgh.png' },
'vTdoZmDfTutEQphHttYyiY.png': { uri: '/images/vTdoZmDfTutEQphHttYyiY.png' },
'8X69Lw636bC69rY9JTABQ1.png': { uri: '/images/8X69Lw636bC69rY9JTABQ1.png' },
'7mDGQWtVPfYGt7TRZfQREi.png': { uri: '/images/7mDGQWtVPfYGt7TRZfQREi.png' },
'4PFR14eRgcYv94WkyLiNtT.png': { uri: '/images/4PFR14eRgcYv94WkyLiNtT.png' },
'4BUNQyiB5hGyFhqyp1pd7q.png': { uri: '/images/4BUNQyiB5hGyFhqyp1pd7q.png' },
'b1YcRcr4cPqbWqeaVTToMi.png': { uri: '/images/b1YcRcr4cPqbWqeaVTToMi.png' },
'6S9jS4KvVpJF4mJNSSug5h.png': { uri: '/images/6S9jS4KvVpJF4mJNSSug5h.png' },
'c7bcTNQdEU7pXciTikPS1p.png': { uri: '/images/c7bcTNQdEU7pXciTikPS1p.png' },
'1NnWxPLv6xDYbpwhs9WEdk.png': { uri: '/images/1NnWxPLv6xDYbpwhs9WEdk.png' },
'dehSQq7br3TWYsBVSWnFbT.png': { uri: '/images/dehSQq7br3TWYsBVSWnFbT.png' },
'aYtMhCsGfGL9ZY93hQ4X6y.png': { uri: '/images/aYtMhCsGfGL9ZY93hQ4X6y.png' },
'h2TzdYA6G63WpjRLtVrjfe.jpg': { uri: '/images/h2TzdYA6G63WpjRLtVrjfe.jpg' },
'29cLqJhE6v3u2YRZ7BfzmJ.png': { uri: '/images/29cLqJhE6v3u2YRZ7BfzmJ.png' },
'8GgZwEzbMsVxmdCZGCuW8b.png': { uri: '/images/8GgZwEzbMsVxmdCZGCuW8b.png' }
}

export default imageStaticSourcesByFileName
