import buildApphiveProcessor from '@apphiveio/controlsmanager/applicationFunctionality/buildApphiveProcessor'
import type { AppStateStore } from '@apphiveio/controlsmanager/hooks/useAppStateStore'
import { odCreatorApp } from 'firebaseConnection'
import store from 'store'
import getRenderConfig from './getRenderConfig'

const buildApplicationProcessor = (
    appStateStore: AppStateStore,
) => buildApphiveProcessor({
    store: appStateStore,
    firebase: {
        auth: () => odCreatorApp.auth(),
        database: () => odCreatorApp.database(),
        functions: () => odCreatorApp.functions(),
        storage: () => odCreatorApp.storage(),
        messaging: () => ({
            getToken: () => odCreatorApp.messaging().getToken(),
            registerDeviceForRemoteMessages: async () => undefined,
        }),
    },
    alert: (title, message) => {
        // eslint-disable-next-line no-alert
        window.alert(`
            ${title}
            ${message}
        `)
    },
    dispatch: (action) => store.dispatch(action),
    getApplicationState: () => store.getState().appState,
    isInDevelopment: getRenderConfig().isRenderer,
    platformOS: 'web',
    getLanguageLocale: () => navigator.language,
})

export default buildApplicationProcessor
